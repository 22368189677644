<template>
    <section class="flex flex-col gap-16 w-full justify-start items-center pb-0 relative overflow-hidden">
        <!-- <div class="py-14 flex flex-col w-full items-center justify-start relative overflow-hidden">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute -z-50 w-full h-full">
                <path
                    d="M1257.34 17.9138C581.525 123.478 93.4572 7.40501 -27.2947 0L-75.5963 45.959C-263.475 170.475 -687.452 467.809 -132.464 717.102C422.523 966.394 1314.21 858.497 1399.13 731.124L1563.11 575.317C1605.57 329.01 1628.55 -40.0705 1257.34 17.9138Z"
                    fill="#F5F8FF"
                />
            </svg>
            <h2 class="w-full text-center text-sm lg:text-base font-semibold text-[#5D5FEF] mb-4">TESTIMONIALS</h2>
            <h1 class="w-full text-center text-xl lg:text-3xl font-bold text-slate-700 mb-6 lg:mb-12">Check what our clients are saying</h1>
            <div
                class="w-11/12 lg:w-full rounded-xl lg:rounded-none flex flex-col lg:flex-row justify-start lg:justify-center items-center bg-gradient-to-b from-[#5D5FEF] to-[#FF725E] lg:bg-none p-6"
            >
                <div class="w-60 h-72 lg:w-[15rem] lg:h-[19rem] flex justify-center items-center relative">
                    <div class="rounded-xl w-full h-full overflow-hidden">
                        <img loading="lazy" decoding="async" src="../../assets/Images/Photo_testimony.jpg" class="object-fill w-full h-full z-0" alt="testimony" />
                    </div>
                    <div class="absolute w-fit h-fit -top-8 -left-4 z-10">
                        <div class="relative w-8 h-8 overflow-hidden">
                            <div class="w-16 h-16 rounded-full absolute left-0 top-0 bg-[#00E1F0]"></div>
                        </div>
                    </div>
                    <div class="absolute w-fit h-fit -top-2 left-2 z-10">
                        <div class="relative w-8 h-8 overflow-hidden">
                            <div class="w-16 h-16 rounded-full absolute left-0 top-0 bg-[#3734A9]"></div>
                        </div>
                    </div>
                    <div class="absolute w-fit h-fit -bottom-12 -right-20 z-10">
                        <div class="relative w-24 h-24 overflow-hidden">
                            <div class="w-48 h-48 rounded-full absolute right-0 bottom-0 bg-[#00E1F0]"></div>
                        </div>
                    </div>
                    <div class="zig-zag-line z-10 absolute lg:-bottom-10 lg:left-1/2 lg:-translate-x-1/2 rotate-90 lg:rotate-0 bottom-1/2 -left-16"></div>
                </div>
                <div class="flex flex-col justify-start items-center w-11/12 lg:max-h-[90%] lg:w-[50%] px-8 py-2 relative lg:bg-gradient-to-b from-[#5D5FEF] to-[#FF725E] bg-transparent rounded-e-xl">
                    <font-awesome-icon :icon="['fas', 'quote-left']" class="w-6 h-6 absolute left-8 -top-8 text-[#FF7F5C]" />
                    <div class="flex w-full flex-row justify-start items-center mb-4">
                        <font-awesome-icon v-for="star in stars" :key="star" class="w-5 h-5 text-[#FF7F5C]" :icon="['fas', 'star']" />
                    </div>
                    <p class="text-base font-normal w-full text-white text-left mb-6">
                        Is be upon sang fond must shew. Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now.
                    </p>
                    <h2 class="text-xs text-left text-white mr-auto font-semibold">AR Shakir</h2>
                    <h2 class="text-xs text-left text-white mr-auto font-light">CEO GetNextDesign</h2>
                </div>
            </div>
        </div> -->
        <div class="bg-gradient-to-r from-[#3DB5E6] to-[#312F92] flex flex-col w-full justify-start items-center py-20 text-white">
            <h2 class="w-full text-center text-sm lg:text-base font-semibold mb-4">{{ $t("UNDERSTAND. DEVELOP. MOBILIZE.") }}</h2>
            <h1 class="font-bold text-3xl lg:text-5xl text-center w-11/12 lg:w-1/2 my-6">{{ $t("Unlock the full potential of your workforce") }}</h1>
            <p class="w-10/12 lg:w-2/5 text-justify lg:text-center font-light text-base my-6">
                {{ $t("Learn how GO PLATFORM can power digital transformation and produce measurable results across your enterprise.") }}
            </p>
            <button @click="() => $router.push('/planning')" class="w-fit px-12 h-fit py-4 text-sm font-bold rounded bg-gradient-to-r to-[#3DB5E6] from-[#5D5FEF] my-6">
                {{ $t("FREE FOREVER") }}
            </button>
        </div>

        <div class="absolute rounded-full border-2 border-[#5236FF] w-[50rem] h-[50rem] hidden lg:block right-0 lg:translate-x-[70%] bottom-0 -translate-y-[5rem] bg-transparent"></div>
        <div class="absolute rounded-full border-2 border-[#5236FF] w-[38rem] h-[38rem] hidden lg:block right-0 lg:translate-x-[80%] bottom-0 -translate-y-[11rem] bg-transparent"></div>
    </section>
</template>

<script>
export default {
    name: "TestimonySection",
    data() {
        return {
            stars: 5,
        };
    },
};
</script>

<style lang="scss" scoped>
/* HTML: <div class="zig-zag-line"></div> */
.zig-zag-line {
    --a: 90deg; /* control the angle */
    --s: 10px; /* size of the zig-zag */
    --b: 3px; /* control the thickness */

    background: #3361ff;
    height: calc(var(--b) + var(--s) / (2 * tan(var(--a) / 2)));
    width: 5rem;
    --_g: var(--s) repeat-x conic-gradient(from calc(var(--a) / -2) at bottom, #0000, #000 1deg calc(var(--a) - 1deg), #0000 var(--a));
    mask:
        50% calc(-1 * var(--b)) / var(--_g) exclude,
        50% / var(--_g);
}
</style>
