<template>
    <section class="flex flex-col w-full justify-start items-center pb-10 my-8">
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
        <div class="px-2 lg:px-12 w-full flex flex-col justify-start items-center gap-10 py-8 mb-6">
            <h2 class="w-full text-center font-bold text-slate-700 text-base lg:text-xl">{{ $t("Our assessments are inspired by the following companies and more") }}</h2>
            <Vue3Marquee :cloned="true" :pauseOnHover="false" :gradient="true" :gradient-color="'#fff'" gradientLength="30%">
                <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../../assets/landing-page-logos/aws.svg" alt="Partner" />
                <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../../assets/landing-page-logos/ey.svg" alt="Partner" />
                <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../../assets/landing-page-logos/pwc.svg" alt="Partner" />
                <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../../assets/landing-page-logos/kpmg.svg" alt="Partner" />
                <img loading="lazy" decoding="async" class="mx-10 lg:mx-20 w-32 aspect-video object-contain mix-blend-color-burn" src="../../assets/landing-page-logos/deloitte.svg" alt="Partner" />
            </Vue3Marquee>
        </div>
        <hr class="h-[5px] bg-[#F5F8FF] w-full" />

        <div class="valuesWrapper px-2 mt-8 lg:px-[5.5rem] w-full relative flex flex-col lg:flex-row overflow-hidden">
            <div class="w-96 h-96 bg-[#F5F8FF] rounded-full opacity-80 absolute top-4 -right-24 -z-10"></div>
            <!-- <svg width="1070" height="449" viewBox="0 0 1070 449" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute -left-2 top-12 hidden lg:block">
                <g filter="url(#filter0_d_5643_23769)">
                    <path
                        d="M26.5 311C74.5 346 186.3 412 249.5 396C328.5 376 348 273 470 249C592 225 681.5 308.5 776 188C870.5 67.5003 916.5 -11.4997 1043.5 5.50027"
                        stroke="#F57059"
                        stroke-width="5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <defs>
                    <filter id="filter0_d_5643_23769" x="0" y="0.703613" width="1070" height="448.255" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="24" />
                        <feGaussianBlur stdDeviation="12" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.203922 0 0 0 0 0.662745 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5643_23769" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5643_23769" result="shape" />
                    </filter>
                </defs>
            </svg> -->

            <div class="lg:absolute top-0 left-[5.5rem] flex flex-col items-center justify-between gap-6 w-72 mx-auto lg:mx-0 lg:w-[28rem]">
                <h2 class="w-full text-center font-bold text-[#5D5FEF] text-xs lg:text-sm lg:text-left">{{ $t("DATA THAT POWERS TRANSFORMATION") }}</h2>
                <h1 class="w-full text-slate-700 text-3xl lg:text-5xl text-center lg:text-left font-bold">
                    <span class="relative overflow-visible whitespace-nowrap text-white">
                        <div class="-rotate-[3deg] bg-[#F86E52] absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                        {{ $t("Skills") }}
                    </span>
                    {{ $t("Assessment Platform") }}
                </h1>
                <p class="w-full text-justify lg:text-left font-light text-sm lg:text-base text-slate-500">
                    {{ $t("We provide actionable skills data that inform your talent strategies across hiring, upskilling, and mentorship.") }}
                </p>
                <button
                    @click="() => $router.push('/register')"
                    class="w-48 px-12 h-12 bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] rounded-sm text-base text-center text-white font-bold mx-auto lg:mx-0 lg:mr-auto"
                >
                    {{ $t("Get started") }}
                </button>
            </div>
            <div class="value w-72 my-8 mx-auto gap-4 lg:w-80 h-fit lg:h-[36rem] border-0 border-red-600 flex flex-col items-center justify-center lg:justify-end">
                <div class="w-12 h-12 hidden lg:block rounded-full border-[0.75rem] border-white shadow-card bg-gray-400 mr-auto"></div>
                <h2 class="font-bold text-base lg:text-lg bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text mr-auto text-center lg:text-left">
                    {{ $t("Understand your talent") }}
                </h2>
                <p class="font-light text-sm lg:text-base text-justify lg:text-left text-slate-500">{{ $t("Party we years to order allow asked of. We so opinion friends me message as delight.") }}</p>
            </div>
            <div class="value1 value w-72 my-8 mx-auto gap-4 lg:w-80 h-fit lg:h-[36rem] border-0 border-red-600 flex flex-col items-center justify-center">
                <div class="w-12 h-12 hidden lg:block rounded-full border-[0.75rem] border-white shadow-card bg-gray-400 mr-auto"></div>
                <h2 class="font-bold text-base lg:text-lg bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text mr-auto text-center lg:text-left">
                    {{ $t("Develop their Skills") }}
                </h2>
                <p class="font-light text-sm lg:text-base text-justify lg:text-left text-slate-500">
                    {{ $t("His defective nor convinced residence own. Connection has put impossible own apartments boisterous.") }}
                </p>
            </div>
            <div class="value2 value w-72 my-8 mx-auto gap-4 lg:w-80 h-fit lg:h-[36rem] border-0 border-red-600 flex flex-col items-center justify-center lg:justify-start">
                <div class="w-12 h-12 hidden lg:block rounded-full border-[0.75rem] border-white shadow-card bg-gray-400 mr-auto"></div>
                <h2 class="font-bold text-base lg:text-lg bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text mr-auto text-center lg:text-left">
                    {{ $t("Mobilize your workforce") }}
                </h2>
                <p class="font-light text-sm lg:text-base text-justify lg:text-left text-slate-500">
                    {{ $t("From they fine john he give of rich he. They age and draw mrs like. Improving end distrusts may instantly.") }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// gsap.registerPlugin(MotionPathPlugin);
// gsap.registerPlugin(ScrollTrigger);
import { Vue3Marquee } from "vue3-marquee";
export default {
    name: "ValueSection",
    components: { Vue3Marquee },
    mounted() {
        // const tl= gsap.timeline({
        //     repeat: 0,
        // })
        // gsap.from(".value", {
        //     y: 700,
        //     duration: 1,
        //     stagger: 0.5,
        //     scrollTrigger: {
        //         trigger: ".valuesWrapper",
        //         // pin: true,
        //         start: "20% 30%",
        //         // end: "bottom 50%",
        //         toggleActions: "play none restart reset",
        //         // markers:{ startColor:"#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20 }
        //     },
        // });
        // ScrollTrigger.create({
        //     trigger: ".valuesWrapper",
        //         pin: true,
        //         start: "top 5%",
        //         end: "+=" + 100,
        //         // animation: tween,
        //         toggleActions: "play none none play",
        //         scrub:1,
        //         markers:{
        //             startColor:"#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20
        //         }
        // })
        // tl.from(".value",{
        //     y:3000,
        //     duration: 3,
        //     stagger: 0.5,
        //     scrollTrigger:{
        //         trigger: ".valuesWrapper",
        //         pin: ".valuesWrapper",
        //         start: "top 5%",
        //         toggleActions: "play none none play",
        //         // markers:{
        //             // startColor:"#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20
        //         // }
        //     }
        // })
        // tl.from(".value1",{
        //     y:3000,
        //     duration: 3,
        //     stagger: 0.5,
        //     scrollTrigger:{
        //         trigger: ".valuesWrapper",
        //         pin: ".valuesWrapper",
        //         start: "top 40%",
        //         toggleActions: "play none none play",
        //         // markers:{
        //             // startColor:"#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20
        //         // }
        //     }
        // })
        // tl.from(".value2",{
        //     y:3000,
        //     duration: 3,
        //     stagger: 0.5,
        //     scrollTrigger:{
        //         trigger: ".valuesWrapper",
        //         pin: ".valuesWrapper",
        //         start: "top 80%",
        //         toggleActions: "play none none play",
        //         // markers:{
        //             // startColor:"#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20
        //         // }
        //     }
        // })
    },
};
</script>

<style lang="scss" scoped></style>
